import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ExclusiveVendors: [],
  ceremony_type: [],
  RecentlyJoinedVendors: [],
  CommunityPosts: [],
  VendorCountCategories: [],
};

const slice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    setExclusiveVendors: (state, action) => {
      state.ExclusiveVendors = action.payload;
    },
    setceremony_type: (state, action) => {
      state.ceremony_type = action.payload;
    },
    setRecentlyJoinedVendors: (state, action) => {
      state.RecentlyJoinedVendors = action.payload;
    },
    setCommunityPosts: (state, action) => {
      state.CommunityPosts = action.payload;
    },
    setVendorCountCategories: (state, action) => {
      state.VendorCountCategories = action.payload;
    },
  },
});

export const {
  setExclusiveVendors,
  setceremony_type,
  setRecentlyJoinedVendors,
  setCommunityPosts,
  setVendorCountCategories,
} = slice.actions;
export default slice.reducer;
