import "./App.css";
import Routes from "routes/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function App() {
  const { globalLoading } = useSelector((state) => state?.settings) ?? "";
  localStorage.setItem("color", "color-1");
  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = globalLoading
        ? null
        : "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div className="App">
      <Routes />
      <ToastContainer limit={1} />
    </div>
  );
}

export default App;

{
  /* <Switch>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={DemoApp} />
        <Route
          path={`${process.env.PUBLIC_URL}/home-one`}
          component={HomeOne}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-two`}
          component={HomeTwo}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/home-three`}
          component={HomeThree}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-details`}
          component={BlogDetails}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-list`}
          component={BlogList}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-left-sidebar`}
          component={BlogLeftSidebar}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-right-sidebar`}
          component={BlogRightSidebar}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-leftside`}
          component={BlogLeftSide}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/blog-rightside`}
          component={BlogRightSide}
        />
        <Route path={`${process.env.PUBLIC_URL}/sign-in`} component={SignIn} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/sign-up`}
          component={SignUp}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/forget-password`}
          component={ForgetPwd}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/thank-you`}
          component={ThankYou}
        />
        <Route path={`${process.env.PUBLIC_URL}/review`} component={Review} />
        <Route
          path={`${process.env.PUBLIC_URL}/404`}
          component={PageNotFound}
        />
        <Route path={`${process.env.PUBLIC_URL}/faq`} component={FAQ} />
        <Route
          path={`${process.env.PUBLIC_URL}/download`}
          component={Download}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/coming-soon`}
          component={ComingSoon}
        />
        <Route component={PageNotFound} />
      </Switch> */
}
