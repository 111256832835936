import React from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { APIPATH } from "./api";

export const statusMessage = {
  pending: "pending",
  isSend: "isSend",
  isRead: "isRead",
};

// Payment

export const LiveCHANNEL = 10397957; // realData
export const LiveSECRET = "ef6414a7cd56491aa3a540ad4b940a41"; // realData

export const TestCHANNEL = 11066629; // realData
export const TestSECRET = "7d790fbf664a413697c89a65445bc3a3"; // realData

//-----------

export const navigationRef = React.createRef();
export const googleApiKey = "AIzaSyCsXh92DxwmmSEzAhzcwJZl03QXD3FU5vo";
export const Page = 10;

export const scrollToInput = (scrollViewRef, positionInput, ref) => {
  // try {
  //   if (scrollViewRef && scrollViewRef?.current && positionInput) {
  //     setTimeout(() => {
  //       scrollViewRef?.current?.scrollTo({
  //         y: positionInput,
  //         animated: true,
  //       });
  //     }, 1000);
  //   }
  // } catch (err) {
  //   console.log({errorScroll: err});
  // }
};

export const CheckItemIfExit = (id, array) => {
  let isExit = array?.some((element) => element?.id == id);
  return isExit;
};

export const CheckRecentVendorIDfExist = (id, array) => {
  let isExit = array?.some((element) => element == id);
  return isExit;
};

export const removeItemFromArray = (id, array) => {
  let newArray = array?.filter((item) => item?.id !== id);
  return newArray;
};
export const getItemFromArray = (id, array) => {
  let newArray = array?.filter((item) => item?.id == id);
  return newArray[0];
};
export const AddItemToArray = (item, array) => {
  return [...array, item];
};

export async function handleError(error) {
  let errorResponse = {
    error: error,
    text: "An error ocurred. Please try again later.",
  };
  return errorResponse;
}

export function isEquivalent(a, b) {
  try {
    return JSON.stringify(a) === JSON.stringify(b);
  } catch (e) {
    return false;
  }
}
export function isValidArray(array) {
  return array && Array.isArray(array) && array.length > 0;
}

export function validURL(str) {
  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(str)) {
    return true;
  } else {
    return false;
  }
}

export function isNumber(data) {
  if (isNaN(data)) {
    return false;
  } else {
    return true;
  }
}

export async function GetFunction(Route, data, showalert, state, tokens) {
  try {
    let token;
    if (state) {
      if (tokens) {
        token = tokens;
      } else {
        token = state()?.userInformation?.token ?? "";
      }
    }
    if (process.env.NODE_ENV === "development") {
      console.log("This data send to ", Route, ":===>", data);
    }
    return await axios
      .get(
        APIPATH.BASELINK + Route,
        {
          headers: {
            token: token,
          },
        },
        { params: data }
      )
      .then((res) => {
        if (showalert) {
          toast.success("success: " + res?.data?.message);
        }
        if (process.env.NODE_ENV === "development") {
          console.log("this response from", Route, "<===:", res?.data);
        }
        if (res?.data) {
          return res.data;
        } else if (res) {
          return res;
        } else {
          return true;
        }
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          if (process.env.NODE_ENV === "development") {
            console.log(
              `Error response data from:${Route}`,
              error.response.data
            );
            console.log(
              `Error response message from:${Route}`,
              error.response.data.message
            );
            console.log(
              `Error response status from:${Route}`,
              error.response.status
            );
          }
          toast.error("error: " + error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          toast.error(
            "Error: " +
              "sorry we have an error, please check your internet connection and try again."
          );

          if (process.env.NODE_ENV === "development") {
            console.log(`Error request from:${Route}`, error.request);
          }
        } else {
          // Something happened in setting up the request that triggered an Error
          if (process.env.NODE_ENV === "development") {
            console.log(`Error Message from:${Route}`, error.message);
          }
          toast.error(
            "Error: " +
              "sorry we have an error, please check your internet connection and try again."
          );
        }
        return false;
      });
  } catch (err) {
    return false;
  }
}
