import axios from "axios";
import { APIPATH, LANDING_PATH_WEB } from "helpers/api";

export async function contactUsFunction(
  userEmail,
  userPhone,
  name,
  message,
  callback
) {
  try {
    const postData = {
      email: userEmail,
      phone: userPhone,
      name: name,
      message: message,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    const config = {
      method: "post",
      url: APIPATH.BASELINK + LANDING_PATH_WEB.send_contact_message,
      data: postData,
      headers: headers,
    };

    const res = await axios(config);
    if (res) {
      callback && callback(res);
    } else {
      callback && callback(false);
    }
  } catch (error) {
    callback && callback(false);
    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
  }
}
