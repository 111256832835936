import { Alert } from "@mui/material";
import React from "react";

export default function AlertMessage({ message = "There was an error..." }) {
  return (
    <Alert variant="outlined" severity="warning">
      {message}
    </Alert>
  );
}
