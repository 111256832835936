import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";

export const StyledIconButton = styled(IconButton)({
  "&:active": {
    backgroundColor: "#8e48e2",
    borderWidth: 0,
    outline: "none",
  },
  "&:focus": {
    outline: "none",
  },
});
