import { HOME_PATH, LANDING_PATH_WEB } from "helpers/api";
import { GetFunction, Page } from "helpers/helper";

import { setData } from "store/reducers_data/privacy_reducers";

export const getPrivacy =
  (payload, callback) => async (dispatch, getState) => {
    try {
      const data = await GetFunction(
        `${`settings/get_terms_privacy?type=${payload}`}`,
        {},
        false,
        false
      );
      console.log({data})
      if (data) {
        dispatch(setData(payload == 'policy' ? data.data.privacy_policy_en : data.data.terms_and_conditions_en));
        callback && callback(data);
        return data;
      }
    } catch (e) {
      callback && callback(false);
      return false;
    }
  };


