export const APIPATH = {
  //  Online
  BASELINK: process.env.REACT_APP_BASELINK_ONLINE,
  BASELINKIMAGE: process.env.REACT_APP_BASELINKIMAGE_ONLINE,
  //  Local
  // BASELINK: process.env.REACT_APP_BASELINK_LOCAL,
  // BASELINKIMAGE: process.env.REACT_APP_BASELINKIMAGE_LOCAL,
};

export const AUTH_PATH = {
  login: "",
  signUp: "",
  howItWork: "how_it_works",
  signupClient: "auth-users/signup",
  loginClient: "auth-users/login",
  signupVendor: "vendors/add",
  loginVendor: "vendors/login",
  checkemail: "auth-users/check_email",
  vendorDetails: "vendors/get_vendor_info",
  resetPassword: "auth/send_email",
  checkSocialMediaVendors: "vendors/check_service_login_id",
  checkSocialMediaClients: "clients/check_service_login_id",
  deactivateAccount: "auth-users/deactivate_account",
  sendEmail: "auth-users/send_email",
};

export const HOME_PATH = {
  Countries: "address/countries",
  Cities: "address/cities",
  Category: "vendor_category",
  CategoryServices: "vendor_type_services",
  CategorySettings: "vendor_type_settings",
  CeremonyType: "ceremony_type",
  VendorPricing: "vendor_pricing",
  VendorPricingAdd: "vendor_pricing/add",
  VendorPricingRemove: "vendor_pricing/del",
  VendorPricingEdit: "vendor_pricing/put",
  vendorCategory: "vendor_category/by-vendor",
  getFolders: "file_manager/get_folders",
  getFolderById: "file_manager/get_files_by_folders",
  deleteFolder: "file_manager/delete_folder",
  addFolder: "file_manager/add_folder",
  renameFolder: "file_manager/rename_folder",
  uploadImageToFile: "file_manager/upload_file_to_folder",
  deleteImageFromFile: "file_manager/delete_file",
  unit_of_measure: "unit_of_measure",
  Search_Vendors: "vendors/search_vendors",
  Search_Vendors_name: "vendors/search_vendors_name",
  get_events: "events",
  get_exclusive: "vendors/get_exclusive",
  add_favorite: "favorites",
  get_favorite: "favorites/get",
  add_guest: "guestList/add",
  get_guests: "guestList/get",
  delete_guest: "guestList/delete",
  edit_guest: "guestList/edit",
  delete_event: "events/del",
  update_event: "events/put",
  add_request: "requests/add",
  get_vendor_requests: "requests/get_by_vendor",
  update_request: "requests/edit",
  get_trending: "vendors/get_trending",
  logout: "auth-users/logout",
  get_discount_value: "clients_purchase/get_discount_value",
  add_purchase: "clients_purchase/add",
  get_purchase_by_vendor: "clients_purchase/get_purchase_by_vendor",
  update_purchase_by_vendor: "clients_purchase/edit_discount_sent",
  get_purchase_clients: "clients_purchase/get_by_client",
  get_notification: "notification/get",
  read_notification: "notification/read",
  get_recently_viewed: "vendors/get_recently_viewed",
};

export const PROFILE_PATH = {
  ChangePassword: "vendors/change_password",
  ChangePasswordClient: "clients/change_password",
  UpdateProfile: "vendors/update_profile",
  UpdateProfileClient: "clients/put",
  UpdateCoveredLocation: "vendors/update_covered_location",
  updateSettings: "vendors/update_settings",
  updateServices: "vendors/update_services",
  add_review: "reviews/add",
  get_reviews: "reviews",
  delete_review: "reviews/del",
};

export const BUDGET = {
  CategoryEvents: "budget_percentage/getByCermony",
  getVendorsByPrice: "budget_percentage/getVendorsByPrice",
  getPackagesVendorsByPrice: "budget_percentage/getPackagesVendorByPrice",
  get_events_add: "events/add",
};

export const CHAT = {
  getChats: "chat/get_chat",
  getMessages: "chat/get_messages",
  checkChat: "chat/check_chat_id",
  uploadFile: "chat/upload_file",
};
export const COMMUNITY = {
  getPosts: "posts/get",
  getComments: "posts/get_comments",
  getReplayComments: "posts/get_reply_comment",
  uploadImages: "posts/upload_post_images",
  get_post_like: "posts/get_post_likers",
  delete_post: "posts/delete_post",
  delete_comment: "posts/delete_comment",
};

export const SOCKET_CHAT_EMIT = {
  ChangeStatus: "ChangeStatus",
  createchat: "createchat",
  send_message: "send_message",
  open_chat: "open_chat",
  Delete_Message: "Delete_Message",
  //new
  add_post: "add_post", //done
  add_like: "add_like", //done
  add_comment: "add_comment", //done
};

export const SOCKET_CHAT_ON = {
  receivechat: "receivechat",
  receive_message: "receive_message",
  update_chat: "update_chat",
  read_all_message: "read_all_message",
  ReceivedDeleteImage: "ReceivedDeleteImage",
  // new
  received_new_post: "received_new_post", //done
  received_new_like: "received_new_like", // done
  received_new_comment: "received_new_comment",
};

export const PAYMENT = {
  getrate: `${APIPATH.PathpaymentTest}payment/collect/rate`,
  collect: `${APIPATH.PathpaymentTest}payment/collect`,
};

export const SETTINGS_PATH = {
  policies: "settings/get_terms_privacy",
};

export const LANDING_PATH_WEB = {
  get_exclusive_vendors_web: "web/vendors/get_exclusive",
  get_recently_joined_web: "web/vendors/get_recently_join",
  get_vendors_count_web: "web/vendors/get_vendors_count",
  get_community_posts: "web/posts/get",
  send_contact_message: "web/common/contact_us",
};
