import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LinearProgress from "@mui/material/LinearProgress";
import { Box, useMediaQuery } from "@mui/material";
import { contactUsFunction } from "./core";

export default function Contact() {
  const matches = useMediaQuery("(max-width:1200px)");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAlerts, setShowAlerts] = useState({
    firstNameAlert: false,
    messageAlert: false,
    emailAlert: false,
  });
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (value) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(value);
  };

  const emptyFields = () => {
    setName("");
    setMessage("");
    setUserEmail("");
    setUserPhone("");
  };

  const showErrorToast = (text) => {
    toast.warning(text, {
      position: matches ? "top-right" : "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  function handleSubmitContactUs() {
    setShowAlerts(false);
    if (!name && !message && !userEmail) {
      showErrorToast("Please fill in the required fields.");
      setShowAlerts((prev) => ({
        ...prev,
        firstNameAlert: true,
        messageAlert: true,
        emailAlert: true,
      }));
      return;
    } else {
      if (!name) {
        setShowAlerts((prev) => ({ ...prev, firstNameAlert: true }));
      }
      if (!message) {
        setShowAlerts((prev) => ({ ...prev, messageAlert: true }));
      }
      if (!userEmail) {
        setShowAlerts((prev) => ({ ...prev, emailAlert: true }));
      }
    }
    if (!name || !message || !userEmail) {
      return;
    }
    if (!isValidEmail) {
      showErrorToast("Please enter a valid email.");
      return;
    }
    setLoading(true);
    contactUsFunction(userEmail, userPhone, name, message, (res) => {
      if (res) {
        emptyFields();
        setLoading(false);
        toast.success("Success", "Thank you for reaching out!");
      } else {
        emptyFields();
        setLoading(false);
        showErrorToast("Failed", "Failed to send message.");
      }
    });
  }

  const emailChangeHandler = (event) => {
    const inputValue = event.target.value;
    setUserEmail(inputValue);
    setIsValidEmail(validateEmail(inputValue));
  };

  useEffect(() => {
    if (name && message && userEmail) {
      if (showAlerts === true) {
        setShowAlerts(false);
      }
    }
  }, [name, message, userEmail]);

  return (
    <section
      id="contact"
      className="contact"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Contact.png)`,
      }}
    >
      <div className="contact-decor">
        <div className="contact-circle1">
          <img src="assets/images/main-banner12.png" alt="" />
        </div>
        <div className="contact-circle2">
          <img src="assets/images/main-banner1.png" alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-8 col-sm-12">
            <div className="contact-us">
              <div className="w-100">
                <h2 className="title">
                  <span>contact </span>us
                </h2>
                <form className="theme-form">
                  {loading ? (
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress color="secondary" />
                    </Box>
                  ) : null}
                  <div className="form-group">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 md-fgrup-margin">
                        {showAlerts.firstNameAlert ? (
                          <p className="alert_text">Name Required</p>
                        ) : null}
                        <input
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          type="text"
                          className="form-control"
                          placeholder="your name *"
                          required="required"
                          maxLength={20}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <input
                          onChange={(e) => setUserPhone(e.target.value)}
                          value={userPhone.toString()}
                          type="number"
                          className="form-control"
                          placeholder="phone"
                          required="required"
                          maxLength={25}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    {showAlerts.emailAlert ? (
                      <p className="alert_text">Email Required</p>
                    ) : null}
                    <input
                      onChange={emailChangeHandler}
                      value={userEmail}
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="email address *"
                      required="required"
                      maxLength={70}
                    />
                  </div>
                  <div className="form-group">
                    {showAlerts.messageAlert ? (
                      <p className="alert_text">Message Required</p>
                    ) : null}
                    <textarea
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="4"
                      placeholder="message *"
                      required="required"
                      maxLength={200}
                    ></textarea>
                  </div>
                  <div className="form-button">
                    <button
                      disabled={loading}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmitContactUs();
                      }}
                      className="btn btn-custom theme-color"
                    >
                      send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <img
          className="hide-phone"
          style={{
            position: "absolute",
            right: "220px",
            top: "10px",
          }}
          src={`${process.env.PUBLIC_URL}/assets/images/aboutus.png`}
          alt="aboutus"
        />
      </div>
    </section>
  );
}
