import React, { lazy } from "react";
import { useRoutes } from "react-router-dom";
import Dashboard from "modules/dashboard/Dashboard";
import Privacy from "modules/privacy/Privacy";
import { useNavigate } from "react-router-dom";
// import BlogDetails from "modules/blog/BlogDetails";
// import BlogLeftSide from "modules/blog/BlogLeftSide";
// import BlogLeftSidebar from "modules/blog/BlogLeftSidebar";
// import BlogList from "modules/blog/BlogList";
// import BlogRightSide from "modules/blog/BlogRightSide";
// import BlogRightSidebar from "modules/blog/BlogRightSidebar";
// import DemoApp from "modules/demo-page";
// import ComingSoon from "modules/coming-soon/ComingSoon";
// import Download from "modules/download/Download";
// import FAQ from "modules/FAQ/FAQ";
// import ForgetPwd from "modules/forget-pwd/ForgetPwd";
// import Review from "modules/review/Review";
// import SignIn from "modules/login/SignIn";
// import SignUp from "modules/register/SignUp";
// import ThankYou from "modules/thank-you/ThankYou";
// import TempDashOne from "modules/dashboard/TempDashOne";
// import TempDashTwo from "modules/dashboard/TempDashTwo";

function wait(time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
}

const PageNotFound = lazy(() =>
  wait(500).then(() => import("modules/404/404"))
);

const Routes = () => {
  const navigate = useNavigate();

  const handleNavigateHome = () => {
    navigate("/");
  };

  const routesObj = [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/privacy",
      element: (
        <Privacy handleNavigateHome={handleNavigateHome} privacy={"policy"} />
      ),
    },
    {
      path: "/terms-and-conditions",
      element: (
        <Privacy handleNavigateHome={handleNavigateHome} privacy={"terms"} />
      ),
    },
  ];

  let element = useRoutes(routesObj);
  return <>{element}</>;
};

export default Routes;
