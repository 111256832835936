import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";
import { APIPATH } from "helpers/api";
import AlertMessage from "modules/common/components/AlertMessage";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { StyledIconButton } from "modules/common/components/StyledIconButton";
import PaidIcon from "@mui/icons-material/Paid";
import PhoneIcon from "@mui/icons-material/Phone";
import CategoryIcon from "@mui/icons-material/Category";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Colors } from "helpers/colors";

export default function RecentlyJoinedServiceProviders() {
  const RecentlyJoinedVendors =
    useSelector((state) => state?.landing.RecentlyJoinedVendors) ?? [];

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const sliderRef = useRef(null);

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  // Slick slider Option for Team Members
  const options = {
    arrows: false,
    accessibility: false,
    infinite: true,
    dots: false,
    // dots: true,
    appendDots: (dots) => <span className=".blog-carousel">{dots}</span>,
    marginRight: 30,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const showInMapClicked = (your_lat, your_lng) => {
    window.open("https://maps.google.com?q=" + your_lat + "," + your_lng);
  };

  const DataList = RecentlyJoinedVendors?.map((val, i) => {
    return (
      <div className="team-item d-flex" style={{ marginRight: "30px" }} key={i}>
        <div
          className="team-block"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
          }}
        >
          <div className="team-box">
            {val?.discount === 0 ? null : (
              <p className="shadow-discount discount-style">
                {val?.discount}% Discount
              </p>
            )}

            <div className="team-avtar">
              <img
                style={{
                  padding: val.logo ? null : 20,
                }}
                src={
                  val.logo
                    ? APIPATH.BASELINKIMAGE + val.logo
                    : `${process.env.PUBLIC_URL}/assets/images/null.png`
                }
                alt=""
              />
            </div>

            <div className="team-text">
              <h3 style={{ maxHeight: "30px", height: "30px" }}>{val.name}</h3>
              <div className="card-item-mt">
                <CategoryIcon
                  sx={{ color: Colors.primaryVendor }}
                  fontSize="small"
                />
                <h6 className="card-text-item-category">
                  {val?.cat_name_en.trim()}
                </h6>
              </div>

              {val?.phone ? (
                <div className="card-item">
                  <PhoneIcon
                    sx={{ color: Colors.primaryVendor }}
                    fontSize="small"
                  />
                  <h6 className="card-text-items">
                    <a
                      style={{ color: "black" }}
                      href={"tel:" + val.phone_code + val?.phone}
                    >
                      {val.phone_code + val?.phone}
                    </a>
                  </h6>
                </div>
              ) : null}

              {val?.min_price != 0 && val?.max_price != 0 ? (
                <div className="card-item">
                  <PaidIcon
                    sx={{ color: Colors.primaryVendor }}
                    fontSize="small"
                  />
                  <h6 className="card-text-items">
                    {`Min $${val.min_price} - Max $${val.max_price}`}
                  </h6>
                </div>
              ) : val?.min_price != 0 ? (
                <div className="card-item">
                  <PaidIcon
                    sx={{ color: Colors.primaryVendor }}
                    fontSize="small"
                  />
                  <h6 className="card-text-items">
                    Starting ${val?.min_price}
                  </h6>
                </div>
              ) : (
                <div className="card-item">
                  <h6 className="card-text-items"></h6>
                </div>
              )}

              <button
                className="btn btn-custom-check-map check-loc-btn "
                onClick={() => showInMapClicked(val?.latitude, val?.longitude)}
              >
                Check location
              </button>
              <div style={{ height: "0.5px", backgroundColor: "silver" }} />
            </div>

            <div className="overlay">
              <ul className="team-social">
                {val?.youtube ? (
                  <li>
                    <a onClick={() => openInNewTab(val?.youtube)}>
                      <YouTubeIcon
                        fontSize="small"
                        sx={{ color: Colors.darkerGray }}
                      />
                    </a>
                  </li>
                ) : null}
                {val?.fb_link ? (
                  <li>
                    <a onClick={() => openInNewTab(val?.fb_link)}>
                      <FacebookIcon
                        fontSize="small"
                        sx={{ color: Colors.darkerGray }}
                      />
                    </a>
                  </li>
                ) : null}

                {val.twitter_link ? (
                  <li>
                    <a onClick={() => openInNewTab(val?.twitter_link)}>
                      <TwitterIcon
                        fontSize="small"
                        sx={{ color: Colors.darkerGray }}
                      />
                    </a>
                  </li>
                ) : null}

                <li>
                  <a onClick={() => openInNewTab(val?.insta_link)}>
                    <InstagramIcon
                      fontSize="small"
                      sx={{ color: Colors.darkerGray }}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <section
      id="recently-joined-service-providers"
      className="team"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`,
      }}
    >
      <div className="feature-decor">
        <div className="feature-circle1">
          <img
            style={{ height: "90px", width: "80px" }}
            src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`}
            alt=""
          />
        </div>
      </div>
      <div className="feature-decor">
        <div className="feature-circle1">
          <img
            style={{ height: "150px", width: "140px" }}
            src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`}
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-sm-12">
            <h2 className="title">
              recently joined <span>service providers</span>
            </h2>
          </div>
          <div className="col-sm-12">
            {RecentlyJoinedVendors?.length > 1 ? (
              <div>
                <Slider
                  ref={sliderRef}
                  {...options}
                  className="team-carousel rounded-dots slick-margin"
                >
                  {DataList}
                </Slider>
              </div>
            ) : (
              <AlertMessage
                message="Sorry. We couldn't find any Recently Joined Service Providers at this
              time..."
              />
            )}
            {RecentlyJoinedVendors?.length > 1 ? (
              <Box
                sx={{
                  mt: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <StyledIconButton
                  color="default"
                  sx={{ mx: 1 }}
                  onClick={goToPrevSlide}
                  aria-label="previous"
                >
                  <ArrowBack />
                </StyledIconButton>

                <StyledIconButton
                  color="default"
                  sx={{ mx: 1 }}
                  onClick={goToNextSlide}
                  aria-label="next"
                >
                  <ArrowForward />
                </StyledIconButton>
              </Box>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}
