import { HOME_PATH, LANDING_PATH_WEB } from "helpers/api";
import { GetFunction } from "helpers/helper";
import {
  setCommunityPosts,
  setExclusiveVendors,
  setRecentlyJoinedVendors,
  setVendorCountCategories,
  setceremony_type,
} from "store/reducers_data/landing_reducers";

export const getExclusiveVendors =
  (offset, callback) => async (dispatch, getState) => {
    try {
      const data = await GetFunction(
        `${LANDING_PATH_WEB.get_exclusive_vendors_web}`,
        {},
        false,
        false
      );
      if (data) {
        dispatch(setExclusiveVendors(data.data));
        callback && callback(data);
        return data;
      }
    } catch (e) {
      callback && callback(false);
      return false;
    }
  };

export const getRecentlyJoinedVendors =
  (offset, callback) => async (dispatch, getState) => {
    try {
      const data = await GetFunction(
        `${LANDING_PATH_WEB.get_recently_joined_web}?page_size=10&offset=0`,
        {},
        false,
        false
      );
      if (data) {
        dispatch(setRecentlyJoinedVendors(data.data));
        callback && callback(data);
        return data;
      }
    } catch (e) {
      callback && callback(false);
      return false;
    }
  };

export const getCeremony_type = () => async (dispatch, getState) => {
  try {
    const data = await GetFunction(
      `${HOME_PATH.CeremonyType}/0`,
      {},
      false,
      getState
    );
    dispatch(setceremony_type(data.data));
  } catch (e) {}
};

export const getCommmunityPosts = () => async (dispatch, getState) => {
  try {
    const data = await GetFunction(
      `${LANDING_PATH_WEB.get_community_posts}`,
      {},
      false,
      getState
    );
    dispatch(setCommunityPosts(data.data));
  } catch (e) {}
};

export const getVendorCountCategories = () => async (dispatch, getState) => {
  try {
    const data = await GetFunction(
      `${LANDING_PATH_WEB.get_vendors_count_web}`,
      {},
      false,
      getState
    );

    dispatch(setVendorCountCategories(data.message));
  } catch (e) {}
};
