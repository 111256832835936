import React, { Fragment, useRef } from "react";
import { Box, Grid } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import Slider from "react-slick";
import { APIPATH } from "helpers/api";
import { useSelector } from "react-redux";
// import { StyledIconButton } from "modules/common/components/StyledIconButton";
// import { ArrowBack, ArrowForward } from "@mui/icons-material";
import AlertMessage from "modules/common/components/AlertMessage";
import { Colors } from "helpers/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";

export default function Features() {
  const matches = useMediaQuery("(max-width:1200px)");

  const VendorCountCategories =
    useSelector((state) => state?.landing?.VendorCountCategories) ?? [];
  const { count_by_category } = VendorCountCategories;

  const sliderRef = useRef(null);

  // useEffect(() => {
  //   alert(matches);
  // }, [matches]);

  // const goToPrevSlide = () => {
  //   sliderRef.current.slickPrev();
  // };

  // const goToNextSlide = () => {
  //   sliderRef.current.slickNext();
  // };

  const featuresArray = [
    { id: 1, f: "One stop shop" },
    { id: 2, f: "PYNE Community" },
    { id: 3, f: "Real-Time Budget Tracker" },
    { id: 4, f: "Vendor Comparison Tools" },
    { id: 5, f: "Secure Payment Gateway" },
    { id: 6, f: "Integrated Event Checklist" },
    { id: 7, f: "Dedicated Event Consultation" },
    { id: 8, f: "Innovative Mood Board Creator" },
    { id: 9, f: "Multi-event Creator" },
    { id: 10, f: "Vendor Rating and Reviews" },
    { id: 11, f: "Detailed Vendor Profiles" },
    { id: 12, f: "Event Timeline and Scheduler" },
    { id: 13, f: "Comprehensive Event Analytics" },
    { id: 14, f: "Exclusive Vendor Deals and Discounts" },
  ];

  const imageLinks = [
    {
      alt: "vendor-profile",
      src: `${process.env.PUBLIC_URL}/assets/images/app/vendor-profile.png`,
      description: "Vendor Profile",
    },
    {
      alt: "chat",
      src: `${process.env.PUBLIC_URL}/assets/images/app/chat.png`,
      description: "Chat",
    },
    {
      alt: "vendor-requests",
      src: `${process.env.PUBLIC_URL}/assets/images/app/vendor-requests.png`,
      description: "Vendor Requests",
    },
    {
      alt: "vendor-profile",
      src: `${process.env.PUBLIC_URL}/assets/images/app/vendor-profile.png`,
      description: "Vendor Profile",
    },
    {
      alt: "community",
      src: `${process.env.PUBLIC_URL}/assets/images/app/community.png`,
      description: "Community",
    },
    {
      alt: "edit-profile",
      src: `${process.env.PUBLIC_URL}/assets/images/app/edit-profile.png`,
      description: "Edit Vendor Profile",
    },
  ];

  const CeremonySliderOptions = {
    arrows: false,
    accessibility: false,
    infinite: true,
    dots: false,
    margin: 30,
    speed: 300,
    autoplaySpeed: 1700,
    slidesToShow: 6,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "110px",
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Show only 1 slide at a time on mobile
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2, // Show only 1 slide at a time on smaller mobile screens
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2, // Show only 1 slide at a time on the smallest mobile screens
          slidesToScroll: 1,
        },
      },
    ],
  };

  const ScreenshotOptions = {
    arrows: false,
    accessibility: false,
    infinite: true,
    dots: false,
    margin: 30,
    speed: 300,
    autoplaySpeed: 1700,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "110px",
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "132px",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "132px",
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: matches ? "90px" : "50px", // - for iPhone
        },
      },
    ],
  };

  const styles = {
    vendor_title: {
      textAlign: "left",
      marginTop: "130px",
      fontSize: "20px",
    },
    ceremony_item_child: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      marginBottom: "10%",
    },
    sectionStyle: {
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/feature-bg.png)`,
      backgroundSize: "100%",
      backgroundPosition: "center 10%", // Adjust the percentage value as needed
    },
  };

  return (
    <section id="feature" className="feature" style={styles.sectionStyle}>
      <section className="feature-inner">
        <div className="feature-decor">
          <div className="feature-circle1">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`}
              alt=""
            />
          </div>
        </div>
        <div className="container">
          <div container spacing={2}>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/222.png`}
              className="ImageMobile2 none-selectable"
              alt=""
            />

            <div className="offset-lg-4 col-lg-8">
              <div className="row">
                <div className="col-sm-12 mrgn-md-top">
                  <h2 className="title">
                    features of<span> PYNE</span>
                  </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  {/*  - - - - - - -  FEATURE TITLES - - - - - - - */}
                  <Grid container spacing={2}>
                    {featuresArray?.map((i, x) => {
                      return (
                        <Grid item xs={6}>
                          <div className="feature-subtitle">
                            <CircleIcon sx={{ fontSize: 6 }} />
                            <h3>{i?.f}</h3>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* - - - - - - - VENDOR TYPES - - - - - - - - */}
        {count_by_category?.length > 1 ? (
          <div className="container">
            <h2 className="title" style={styles.vendor_title}>
              Vendor <span>Types</span>
            </h2>
            <Slider
              autoplay={true}
              {...CeremonySliderOptions}
              className="categories-carousel"
            >
              {count_by_category?.map((item, index) => (
                <Fragment key={index?.toString()}>
                  <div className="ceremony-item">
                    <div style={styles.ceremony_item_child}>
                      <img
                        style={{
                          height: "auto",
                          width: "45px",
                          borderRadius: 5,
                          alignSelf: "center",
                        }}
                        className="img-fluid none-selectable"
                        src={APIPATH.BASELINKIMAGE + item?.image}
                        alt="icon"
                      />
                      <span className="ceremony-text">{item?.cat_name_en}</span>
                    </div>
                    <VisibilitySensor partialVisibility offset={{ bottom: 5 }}>
                      {({ isVisible }) => (
                        <h4 className="vendor-count">
                          {isVisible ? (
                            <CountUp duration={3} end={item?.count} start={0} />
                          ) : (
                            "0"
                          )}{" "}
                          {item?.count === 1 ? "Vendor" : "Vendors"}
                        </h4>
                      )}
                    </VisibilitySensor>
                  </div>
                </Fragment>
              ))}
            </Slider>
          </div>
        ) : (
          <Box
            sx={{
              mt: 13,
              width: "50%",
              ml: "25%",
            }}
          >
            <AlertMessage
              message="Sorry. We couldn't find any Ceremony types at this
            time..."
            />
          </Box>
        )}
        {/* - - - - - - - - IPHONE SCREENSHOTS - - - - - - - -  */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="screenshot-contain">
                <img
                  className="mobile-light-left"
                  src={`${process.env.PUBLIC_URL}/assets/images/light.png`}
                  alt="light"
                />
                <img
                  className="iphone14-mocup img-fluid none-selectable"
                  src={`${process.env.PUBLIC_URL}/assets/images/scene-1.png`}
                  alt="screenshot-mob"
                />
                <img
                  className="mobile-light-right"
                  src={`${process.env.PUBLIC_URL}/assets/images/light-right.png`}
                  alt="light-right"
                />
                <Slider
                  autoplay={true}
                  {...ScreenshotOptions}
                  className="screenshot-carousel slick-margin none-selectable"
                >
                  {imageLinks?.map((item, index) => (
                    <div key={index?.toString()} className="screenshot-item">
                      <img
                        src={item?.src}
                        alt={item?.alt}
                        className="img-fluid round_egdes none-selectable"
                      />
                      <h3
                        style={{
                          color: Colors.darkText,
                          textAlign: "center",
                          fontSize: "13px",
                        }}
                      >
                        {item?.description}
                      </h3>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* {ceremony_type?.length > 1 ? (
        <Box
          sx={{
            mt: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledIconButton
            color="default"
            sx={{ mx: 1 }}
            onClick={goToPrevSlide}
            aria-label="previous"
          >
            <ArrowBack />
          </StyledIconButton>

          <StyledIconButton
            color="default"
            sx={{ mx: 1 }}
            onClick={goToNextSlide}
            aria-label="next"
          >
            <ArrowForward />
          </StyledIconButton>
        </Box>
      ) : null} */}
    </section>
  );
}
