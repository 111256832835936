import { combineReducers, configureStore } from "@reduxjs/toolkit";
import landingReducers from "./reducers_data/landing_reducers";
import privacyReducers from "./reducers_data/privacy_reducers";
import settingsReducers from "./reducers_data/settings_reducers";

const nodeEnv = process.env.NODE_ENV;

const rootReducer = combineReducers({
  landing: landingReducers,
  settings: settingsReducers,
  privacy: privacyReducers,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: nodeEnv === "development",
});

export default store;
