import { useSelector } from "react-redux";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export default function About() {
  const VendorCountCategories =
    useSelector((state) => state?.landing?.VendorCountCategories) ?? [];

  return (
    <section
      id="about"
      className="about"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about-bg.png)`,
      }}
    >
      <div className="about-decor">
        <div className="about-circle1">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/team1.png`}
            alt="team1"
          />
        </div>
        <div className="about-circle2">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`}
            alt="banner1"
          />
        </div>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-md-5">
            <div className="about-contain">
              <div>
                <h2 className="title">
                  about the <span>PYNE app</span>
                </h2>
                <p style={{ textTransform: "none" }} className="caption-about">
                  Turn your event planning into a pleasure with PYNE, your
                  ultimate partner for creating unforgettable moments. PYNE
                  simplifies the complexity of event organization, delivering an
                  experience that is stress-free and uniquely tailored to your
                  vision and budget. From the magical intimacy of birthdays to
                  the grandeur of weddings, PYNE helps you design your dream
                  event in Lebanon. Discover the perfect fit from our extensive
                  network, encompassing everything from renowned wedding
                  planners to captivating venues, gourmet caterers, exceptional
                  florists, and all-embracing service providers. With PYNE,
                  event planning isn't just effortless - it's also an exciting
                  journey of exploration and personalization.
                </p>
                <div className="row sm-mb">
                  <div className="col-6">
                    <ul className="about-style">
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/vendor.png`}
                              alt="easy-to-customized"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                        <VisibilitySensor
                          partialVisibility
                          offset={{ bottom: 5 }}
                        >
                          {({ isVisible }) => (
                            <div className="about-text">
                              <h3>
                                {isVisible ? (
                                  <CountUp
                                    duration={4}
                                    end={VendorCountCategories?.count_vendors}
                                    start={0}
                                  />
                                ) : (
                                  "0"
                                )}{" "}
                                Vendors
                              </h3>
                            </div>
                          )}
                        </VisibilitySensor>
                      </li>
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/icon1000.png`}
                              alt="easy-to-use"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>Comprehensive Database</h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="about-style">
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/icon100.png`}
                              alt="easy-to-customized"
                              style={{ width: "115%" }}
                            />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>User Friendly</h3>
                        </div>
                      </li>
                      <li className="abt-hover">
                        <div className="about-icon">
                          <div className="icon-hover">
                            <img
                              src={`${process.env.PUBLIC_URL}/assets/images/icon1111.png`}
                              alt="SEO-Friendly"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </div>
                        <div className="about-text">
                          <h3>Multilingual Interface</h3>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="top-margin">
                  <button
                    type="button"
                    className="btn btn-custom theme-color theme-color"
                  >
                    view more
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-7 d-medium-none">
            <div className="about-right">
              <div className="about-phone">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/aboutus.png`}
                  className="img-fluid"
                  alt="aboutus"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
