import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  globalLoading: false,
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setGlobalLoading: (state, action) => {
      state.globalLoading = action.payload;
    },
  },
});

export const { setGlobalLoading } = slice.actions;
export default slice.reducer;
