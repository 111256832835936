import Slider from "react-slick";

export default function Us() {
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <img
        className="owl-next"
        src={`${process.env.PUBLIC_URL}/assets/images/next.png`}
        alt="next-arrow"
      />
    </button>
  );

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <img
        className="owl-prev"
        src={process.env.PUBLIC_URL + "/assets/images/back.png"}
        alt="back-arrow"
      />
    </button>
  );

  // Slick slider Option for Testimonial
  const options = {
    arrows: true,
    infinite: true,
    dots: true,
    appendDots: (dots) => (
      <span className=".blog-carousel.owl-theme .owl-dots .owl-dot.active span">
        {dots}
      </span>
    ),
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    marginRight: 30,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          margin: 0,
        },
      },

      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  let data = [
    {
      name: "Michel Nader",
      designation: "Founder and CEO ",
      photo: `${process.env.PUBLIC_URL}/assets/images/avtar/Michel.jpeg`,
      description:
        "Multilingual MBA Alumni at HEC Paris, Michel demonstrates an expansive 7+ year track record spanning Management Consulting, Oil & Gas, and Energy sectors. His strategic acumen has led to successful advancements in the Saudi public sector, notably within Education, GRC, National Industry, Shared Services, and Public Development Funds. As the ex-President of the HEC Paris Consulting Club, Michel combines leadership prowess with deep industry knowledge, marking him as an outstanding asset in any dynamic business environment.",
    },
    {
      name: "Nastasia Safity",
      designation: "Operations Manager",
      photo: `${process.env.PUBLIC_URL}/assets/images/avtar/nastasia.jpeg`,
      description:
        "Nastasia Safity, boasting an illustrious background in Electromechanical Engineering from the esteemed USJ—ESIB, has honed her expertise with an expansive international experience in both MEP Engineering and Supply Chain & Logistics, primarily focusing on regions like the GCC and Africa. Serving as the pivotal driving force behind all of PYNE's operations in Lebanon, Nastasia adeptly manages vendor data collection and interfaces, and orchestrates the internal CMS (Client Management System). In addition to these roles, she also spearheads the recruitment process and imparts essential training to the PYNE Sales force, shaping them into proficient professionals.",
    },
    {
      name: "Hazem Noureddine",
      designation: "Board of Advisors | Strategy",
      photo: `${process.env.PUBLIC_URL}/assets/images/avtar/Hazem.jpeg`,
      description:
        "Seasoned strategy consultant that combines practical entrepreneurial know-how with strategic acumen. Serving 15+ government and private sector clients on critical strategic matters such as expansion, product diversification, and pricing, Hazem brings a wealth of knowledge to the table. Additionally, he has been involved in the venture capital domain, sourcing over 30 YCombinator startups",
    },
    {
      name: "DJ RABZ",
      designation: "Board of Advisors | Market",
      photo: `${process.env.PUBLIC_URL}/assets/images/avtar/dj.jpeg`,
      description:
        "An esteemed DJ with 18+ years of experience, DJ RABZ has set the rhythm of Lebanon's nightlife, bringing electrifying vibes to numerous venues across the nation. Specializing in open format, his eclectic style has made them a go-to choice for Royal Weddings and celebrity events. DJ RABZ unique ability to tune into the pulse of any crowd and create unforgettable experiences makes him a standout performer in the entertainment industry",
    },
    {
      name: "Mohammad Fawaz",
      designation: "Board of Advisors | Market",
      photo: `${process.env.PUBLIC_URL}/assets/images/avtar/mohamad-fawaz.jpeg`,
      description:
        "Management consultant with an 11+ year track record, Mohammad expertly bridges market advisory services with the wedding and events industry. As a venue owner himself, he pairs his hands-on experience with robust market analysis and strategic acumen, offering unrivaled insights to multinational organizations",
    },
    {
      name: "Antoine Khoury",
      designation: "General Counsel | Mediator",
      photo: `${process.env.PUBLIC_URL}/assets/images/avtar/Antoine.jpeg`,
      description:
        "Antoine Khoury is a dynamic and results-oriented corporate lawyer, general counsel and mediator with finely honed expertise in the technology, e-commerce, startups, FinTech, and innovation sectors. He has extensive international exposure, having worked in various regions, including the UK, Cyprus, the UAE, and other Gulf countries. With a career spanning several years, Antoine has showcased exceptional leadership skills, demonstrating the ability to articulate his vision and effectively collaborate with teams. His remarkable organizational, planning, and time management abilities have consistently yielded outstanding results in his legal roles.",
    },
    {
      name: "Julien Ghoussoub",
      designation: "Lawyer and Legal Advisor",
      photo: `${process.env.PUBLIC_URL}/assets/images/avtar/julien.jpeg`,
      description:
        "Julien Ghoussoub is a lawyer and a member of the Beirut Bar Association. He is experienced in providing various types of legal services to corporations and handling commercial, real estate, civil and criminal litigation. Julien is devoted to business and technology. He assists entrepreneurs build various types of businesses in the MENA region including but not limited to startup companies operating in high technology and electronic commerce. Julien is a soundboard for entrepreneurs, so he builds modern images and identities for businesses and mitigates potential legal risks that might hinder entrepreneurs' growth.",
    },
    {
      name: "Nasos",
      designation: "Digital Marketing",
      photo: `${process.env.PUBLIC_URL}/assets/images/avtar/Logo.jpg`,
      description:
        "NASOS is an Industry-leading digital performance agency providing PYNE with specialized services, including Search Engine Marketing (SEM), Search Engine Optimization (SEO), Marketing Data Analysis, Marketing Automation, Email Marketing, and Ad Performance.",
    },
  ];

  let DataList = data.map((val, i) => {
    return (
      <div className="testimonial-item" key={i}>
        <div className="testimonial-block">
          <div
            className="testimonial-avtar"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/Testimonial3.png)`,
            }}
          >
            <img
              src={val.photo}
              style={{
                width: "90px",
                height: "90px",
                backgroundImage: 'url("path/to/image.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              alt=""
            />
          </div>
          <div className="testimonial-text">
            <p>{val.description}</p>
            <h3>{val.name}</h3>
            <h6>{val.designation}</h6>
          </div>
        </div>
      </div>
    );
  });

  return (
    <section id="testimonial" className="testimonial">
      <div className="testimonial-decor">
        <div className="testi-circle1">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/feature2.png`}
            alt=""
          />
        </div>
        <div className="testi-circle2">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/Testimonial1.png`}
            alt=""
          />
        </div>
      </div>
      <div className="container">
        <h2 className="title">
          Our <span>Team</span>
        </h2>
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <Slider {...options} className="testimonial-carousel">
              {DataList}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}
