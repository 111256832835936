// import Price from "./components/Price"
// import Subscribe from "./components/Subscribe"
import React, { useEffect, useState } from "react"
import Menu from "modules/common/components/Navbar"
import { Tilt } from "react-tilt"
import About from "modules/dashboard/components/About"
import Feature from "./components/Features"
import Preview from "./components/Preview"
import ExclusiveServiceProviders from "./components/ExclusiveServiceProviders"
import PyneCommunity from "./components/PyneCommunity"
import Us from "./components/Us"
import Contact from "./components/contact/Contact"
import Footer from "modules/dashboard/components/Footer"
import { useDispatch } from "react-redux"
import {
  getCeremony_type,
  getCommmunityPosts,
  getExclusiveVendors,
  getRecentlyJoinedVendors,
  getVendorCountCategories,
} from "store/actions/landing_actions"
import CeremonyTypes from "./components/CeremonyTypes"
import RecentlyJoinedServiceProviders from "./components/RecentlyJoinedServiceProviders"
import { setGlobalLoading } from "store/reducers_data/settings_reducers"
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp"
import { Colors } from "helpers/colors"
import { StyledIconButton } from "modules/common/components/StyledIconButton"
import ReactPlayer from "react-player"

export default function Dashboard() {
  const dispatch = useDispatch()
  const [youtubeID, _] = useState("fE627UJ_aRY")
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Show the button when the user scrolls down 100px
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll)

    // Clean up the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const scrollToTop = () => {
    const scrollStep = window.scrollY / 200
    const scrollAnimation = () => {
      if (window.scrollY > 0) {
        window.scrollBy(0, -scrollStep)
        requestAnimationFrame(scrollAnimation)
      }
    }

    requestAnimationFrame(scrollAnimation)
  }

  const getAllData = () => {
    try {
      dispatch(setGlobalLoading(true))
      dispatch(getCeremony_type())
      dispatch(getExclusiveVendors())
      dispatch(getRecentlyJoinedVendors())
      dispatch(getCommmunityPosts())
      dispatch(getVendorCountCategories())
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.log(e)
      }
    } finally {
      dispatch(setGlobalLoading(false))
    }
  }

  useEffect(() => {
    getAllData()
  }, [])

  // document.body.classList.remove("landing-page");
  // document.body.classList.remove("home-style");
  // document.body.classList.remove("three");
  // document.body.classList.remove("home-style-two");

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer")
  }

  const styles = {
    scrollButton: {
      display: isVisible ? "block" : "none",
      position: "fixed",
      bottom: 20,
      right: 20,
      zIndex: 1000,
      backgroundColor: Colors.vendorForm,
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      borderRadius: 55,
      height: "55px",
      width: "55px",
      color: "white",
    },
  }

  return (
    <div>
      <StyledIconButton sx={styles.scrollButton} onClick={scrollToTop}>
        <KeyboardDoubleArrowUpIcon />
      </StyledIconButton>
      <Menu homePage="home-one" />

      <section
        id="home"
        className="home"
        // style={{
        //   backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/1.png)`,
        // }}
      >
        {/* <video
          id="background-video"
          autoplay
          loop
          muted
          style={{ zIndex: -1, position: "absolute", top: 0, width: "100%" }}
        >
          <source
            src="https://youtube.com/watch?v=fE627UJ_aRY"
            type="video/mp4"
          />
        </video> */}
        {/* <iframe
          style={{
            zIndex: -1,
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
          }}
          title="Youtube player"
          allow="autoplay; encrypted-media"
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={`https://youtube.com/embed/${youtubeID}?autoplay=0`}
        ></iframe> */}

        <div className="home-decor">
          {/* <img
            alt="mobile"
            className="none-selectable ImageMobileNew"
            src={`${process.env.PUBLIC_URL}/assets/images/mobile.png`}
          /> */}
          <div className="home-circle1">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/images/main-banner3.png`}
            />
          </div>
          <div className="home-circle2">
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/images/main-banner12.png`}
            />
          </div>
          <div className="home-circle3">
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/main-banner1.png`}
              alt=""
            />
          </div>
        </div>
        <div className="container">
          <div
            style={{
              pointerEvents: "none",
            }}
          >
            <ReactPlayer
              pip
              playing={true}
              config={{ youtube: { playerVars: { disablekb: 1 } } }}
              loop={true}
              muted={true}
              height={"100%"}
              width={"100%"}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
              }}
              url={`https://www.youtube.com/watch?v=${youtubeID}`}
            />
            <div className="black-overlay"></div>
          </div>
          <p
            className="text-on-video"
            style={{
              color: "white",
              textAlign: "center",
              position: "absolute",
              bottom: "20%",
              left: 0,
              right: 0,
              fontWeight: "bold",
              fontSize: "17px",
            }}
          >
            Let's create magical moments.
          </p>
          <div className="row">
            <div className="col-md-5">
              <div
                className="text-on-video"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10%",
                  flexDirection: "column",
                }}
              >
                <h4
                  className="text-on-video"
                  style={{ color: "white", textAlign: "center" }}
                >
                  Welcome to
                </h4>
                <h1>
                  <span
                    style={{ color: "white" }}
                    className="text-on-video f-bold f-color"
                  >
                    PYNE
                  </span>
                </h1>
              </div>
              <div className="home-contain text-on-video-desktop">
                <div className="video-text-desktop">
                  <h4 style={{ color: "white" }}>Welcome to</h4>
                  <h1>
                    <span style={{ color: "white" }} className="f-bold f-color">
                      PYNE
                    </span>
                  </h1>
                  <p style={{ color: "white" }}>
                    Let's create magical moments.
                  </p>
                  <a
                    href="https://apps.apple.com/us/app/pyne/id6448928498"
                    onClick={(e) => {
                      openInNewTab(
                        "https://apps.apple.com/us/app/pyne/id6448928498"
                      )
                      e.preventDefault()
                    }}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`}
                      alt="appstore"
                      className="store"
                    />
                  </a>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.pyne.mena"
                    onClick={(e) => {
                      openInNewTab(
                        "https://play.google.com/store/apps/details?id=com.pyne.mena"
                      )
                      e.preventDefault()
                    }}
                  >
                    <img
                      className="ml-10 store"
                      src={`${process.env.PUBLIC_URL}/assets/images/play-store.png`}
                      alt="play-store"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 offset-md-1">
              <Tilt
                options={{
                  perspective: 110,
                  speed: 400,
                  max: 1.2,
                  scale: 1,
                }}
              ></Tilt>
            </div>
          </div>
        </div>
      </section>
      <About />
      <Feature />
      <ExclusiveServiceProviders />
      <RecentlyJoinedServiceProviders />
      <PyneCommunity />
      <Us />
      <Contact />
      <Footer />
    </div>
  )
}
