export const Colors = {
  primaryClient: "#FAE7CC",
  darkprimaryClient: "#FFB340",
  darkerprimaryClient: "#FFA724",
  mediumPrimaryClient: "#FFDE9E",
  // ---
  primaryVendor: "#8E48E2",
  vendorForm: "#b884ec",
  primaryVendordark: "#8E48E2",
  // ---
  text: "#9EABBF",
  darkText: "#24245c",
  background: "#F5F5F5",
  white: "#FFFFFF",
  gray: "#E1EAF1",
  darkgray: "#94A3B8",
  shadowDark: " rgba(0, 0, 0, 0.477);",
  darkRed: "#E2747A",
  loginDisabledBtn: "#e8ecf4",
  loginDisabledBtnText: "#98a4bc",
  graduationOrange: "#ffb44c",
  musicNavy: "#006acc",
  reunionFlorescent: "#88e4e4",
  corporateGreen: "#308c7c",
  signUpText: "#B087EC",
  loginBG: "#F8FAFC",
  lightBlueShadow: "#2788C5",
  darkerGray: "#49536E",
  authGreen: "#86D8CC",
  calendarBlack: "#454545",
  detailsButtonNavy: "#221841",
  rangeInputBorder: "#CBD5E1",
  bottomBarBG: "#302062",
  pyneEmerald200: "#BDE8E2",
  pyneEmerald500: "#1C9A89",
  pyneEmerald700: "#2E8A7D",
  unselectedBackground: "#CBD5E1",
  womenLightPink: "#F3D9E8",
  womenPink: "#D17CAD",
  entertainmentPurple: "#4B499D",
  entertainmentLightPurple: "#B8B6FF",
  pyneNavy700: "#29278A",
  dotInactive: "#E2E8F0",
  surface: "#F1F5F9",
  lightBlue: "#66B5FF",
  overlay: "rgba(255,0,0,0.2)",
  notifRed: "#ED4C5C",
  Grey100: "#95959D",
  purple: "#7455F6",
};
