// import React, { useEffect } from "react";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import Toolbar from "@mui/material/Toolbar";
// import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
// import Menu from "@mui/material/Menu";
// import MenuIcon from "@mui/icons-material/Menu";
// import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
// import MenuItem from "@mui/material/MenuItem";
// import AdbIcon from "@mui/icons-material/Adb";
// import { Link } from "react-scroll";

// const pages = [
//   {
//     n: "home",
//     l: "home",
//   },
//   {
//     n: "about",
//     l: "about",
//   },
//   {
//     n: "feature",
//     l: "feature",
//   },
//   {
//     n: "preview",
//     l: "screenshot",
//   },
//   {
//     n: "ceremonies",
//     l: "client-ceremonies",
//   },
//   {
//     n: "exclusive",
//     l: "exclusive-service-providers",
//   },
//   {
//     n: "recent",
//     l: "recently-joined-service-providers",
//   },
//   {
//     n: "community",
//     l: "pyne-community",
//   },
//   {
//     n: "us",
//     l: "testimonial",
//   },
//   {
//     n: "contact",
//     l: "contact",
//   },
// ];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

// function ResponsiveAppBar() {
//   const [anchorElNav, setAnchorElNav] = React.useState(null);
//   const [anchorElUser, setAnchorElUser] = React.useState(null);
//   const [shadow, setShadow] = React.useState(false);

//   const handleOpenNavMenu = (event) => {
//     setAnchorElNav(event.currentTarget);
//   };
//   const handleOpenUserMenu = (event) => {
//     setAnchorElUser(event.currentTarget);
//   };

//   const handleCloseNavMenu = () => {
//     setAnchorElNav(null);
//   };

//   const handleCloseUserMenu = () => {
//     setAnchorElUser(null);
//   };

//   const HandleScroll = () => {
//     if (window.scrollY >= 60) {
//       setShadow(true);
//     } else setShadow(false);
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", HandleScroll);
//     return () => {
//       window.removeEventListener("scroll", HandleScroll);
//     };
//   }, []);

//   return (
//     <AppBar
//       elevation={shadow ? 1 : 0}
//       variant="elevation"
//       sx={{
//         backgroundColor: "#fff",
//         padding: shadow ? 1 : 0,
//         transition: shadow ? "elevation 0.3s ease" : "ease-out",
//       }}
//       position="sticky"
//     >
//       <Container maxWidth="xl">
//         <Toolbar disableGutters>
//           <img
//             style={{ height: "50x", width: "150px" }}
//             src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
//             alt="logo"
//           />

//           <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
//             <IconButton
//               size="large"
//               aria-label="account of current user"
//               aria-controls="menu-appbar"
//               aria-haspopup="true"
//               onClick={handleOpenNavMenu}
//               color="info"
//             >
//               <MenuIcon />
//             </IconButton>
//             <Menu
//               id="menu-appbar"
//               anchorEl={anchorElNav}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: "top",
//                 horizontal: "left",
//               }}
//               open={Boolean(anchorElNav)}
//               onClose={handleCloseNavMenu}
//               sx={{
//                 display: { xs: "block", md: "none" },
//               }}
//             >
//               {pages.map((page) => (
//                 <MenuItem key={page.l} onClick={handleCloseNavMenu}>
//                   <Link
//                     className="nav-link"
//                     activeClass="active"
//                     to={page?.l}
//                     spy={true}
//                     smooth={true}
//                     offset={-70}
//                     duration={500}
//                   >
//                     <Typography textAlign="center">{page?.n}</Typography>
//                   </Link>
//                 </MenuItem>
//               ))}
//             </Menu>
//           </Box>
//           <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} />
//           <Typography
//             variant="h5"
//             noWrap
//             component="a"
//             href=""
//             sx={{
//               mr: 2,
//               display: { xs: "flex", md: "none" },
//               flexGrow: 1,
//               fontFamily: "monospace",
//               fontWeight: 700,
//               letterSpacing: ".3rem",
//               color: "inherit",
//               textDecoration: "none",
//             }}
//           >
//             LOGO
//           </Typography>
//           <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" }, ml: 3 }}>
//             {pages.map((page, i) => (
//               // <Button
//               //   variant="text"
//               //   key={page}
//               //   onClick={handleCloseNavMenu}
//               //   sx={{
//               //     my: 2,
//               //     color: "gray",
//               //     display: "block",
//               //   }}
//               // >
//               <Link
//                 activeStyle={{ color: "purple" }}
//                 style={{
//                   color: "gray",
//                   cursor: "pointer",
//                   textTransform: "capitalize",
//                 }}
//                 color="red"
//                 className="nav-link"
//                 activeClass="active"
//                 to={page?.l}
//                 spy={true}
//                 smooth={true}
//                 offset={-70}
//                 duration={500}
//                 // onClick={toggle}
//               >
//                 {page?.n}
//               </Link>
//               // </Button>
//             ))}
//           </Box>

//           {/* <Box sx={{ flexGrow: 0 }}>
//             <Tooltip title="Open settings">
//               <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
//                 <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
//               </IconButton>
//             </Tooltip>
//             <Menu
//               sx={{ mt: "45px" }}
//               id="menu-appbar"
//               anchorEl={anchorElUser}
//               anchorOrigin={{
//                 vertical: "top",
//                 horizontal: "right",
//               }}
//               keepMounted
//               transformOrigin={{
//                 vertical: "top",
//                 horizontal: "right",
//               }}
//               open={Boolean(anchorElUser)}
//               onClose={handleCloseUserMenu}
//             >
//               {settings.map((setting) => (
//                 <MenuItem key={setting} onClick={handleCloseUserMenu}>
//                   <Typography sx={{ color: "black" }} textAlign="center">
//                     {setting}
//                   </Typography>
//                 </MenuItem>
//               ))}
//             </Menu>
//           </Box> */}
//         </Toolbar>
//       </Container>
//     </AppBar>
//   );
// }
// export default ResponsiveAppBar;

import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-scroll";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { StyledIconButton } from "./StyledIconButton";
import { ArrowForward } from "@mui/icons-material";
import useWindowDimensions from "hooks/useWindowDimensions";

const pages1 = [
  {
    name: "home",
    link: "home",
  },
  {
    name: "about",
    link: "about",
  },
  {
    name: "features",
    link: "feature",
  },
];

const pages2 = [
  {
    name: "our team",
    link: "testimonial",
  },
  {
    name: "contact us",
    link: "contact",
  },
];

const Menu = ({ handleNavigateHome }) => {
  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [whiteText, setWhiteText] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const HandleScroll = () => {
    if (window.scrollY >= 60) {
      setWhiteText(false);
      document.getElementById("Navbar")?.classList.add("darkHeader");
    } else {
      setWhiteText(true);
      document.getElementById("Navbar")?.classList.remove("darkHeader");
    }
  };

  window.addEventListener("scroll", HandleScroll);

  useEffect(() => {
    let timer = setTimeout(function () {
      document.querySelector(".loader-wrapper").style = "display: none";
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Navbar
        id="Navbar"
        className="navbar navbar-expand-lg navbar-light theme-nav fixed-top"
      >
        <div id="navbar-main" className="container">
          <NavbarBrand
            className="navbar-brand"
            href={`${process.env.PUBLIC_URL}/`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              style={{ height: 35, width: "auto" }}
              alt="logo"
            />
          </NavbarBrand>
          <NavbarToggler
            style={{
              background: whiteText ? "white" : undefined,
            }}
            className="navbar-toggler"
            onClick={toggle}
          />
          <Collapse
            id="navbarSupportedContent"
            className="default-nav"
            isOpen={isOpen}
            navbar
          >
            <Nav className="ml-auto" navbar>
              {pages1?.map((item, index) => {
                return (
                  <NavItem key={index}>
                    <Link
                      style={{
                        color:
                          item.name === "home"
                            ? undefined
                            : whiteText && width > 700
                            ? "white"
                            : undefined,
                      }}
                      className="nav-link"
                      activeClass="active"
                      to={item?.link}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      onClick={
                        item.link == null
                          ? handleOpen
                          : handleNavigateHome
                          ? () => handleNavigateHome()
                          : toggle
                      }
                      active="true"
                    >
                      {item?.name}
                    </Link>
                  </NavItem>
                );
              })}

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  style={{
                    color: whiteText && width > 700 ? "white" : undefined,
                  }}
                  nav
                  caret
                >
                  Service Providers
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <Link
                      className="nav-link"
                      activeClass="active"
                      to={"exclusive-service-providers"}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      onClick={
                        handleNavigateHome ? () => handleNavigateHome() : toggle
                      }
                      active="true"
                    >
                      Exclusive
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      className="nav-link"
                      activeClass="active"
                      to={"recently-joined-service-providers"}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      onClick={
                        handleNavigateHome ? () => handleNavigateHome() : toggle
                      }
                      active="true"
                    >
                      Recently Joined
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              {pages2?.map((item, _) => {
                return (
                  <NavItem>
                    <Link
                      style={{
                        color: whiteText && width > 700 ? "white" : undefined,
                      }}
                      className="nav-link"
                      activeClass="active"
                      to={item?.link}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      onClick={
                        item.link == null
                          ? handleOpen
                          : handleNavigateHome
                          ? () => handleNavigateHome()
                          : toggle
                      }
                      active="true"
                    >
                      {item?.name}
                    </Link>
                  </NavItem>
                );
              })}

              {/* <NavItem>
              <Link
                className="nav-link"
                activeClass="active"
                to="price"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggle}
              >
                price
              </Link>
            </NavItem>
            <NavItem>
              <Link
                className="nav-link"
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={toggle}
              >
                contact us
              </Link>
            </NavItem> */}
              {/* <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                other page
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <NavLink
                    className="nav-link"
                    href={`${process.env.PUBLIC_URL}/sign-in`}
                    onClick={toggle}
                  >
                    sign in
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="nav-link"
                    href={`${process.env.PUBLIC_URL}/sign-up`}
                    onClick={toggle}
                  >
                    sign up
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="nav-link"
                    href={`${process.env.PUBLIC_URL}/forget-password`}
                    onClick={toggle}
                  >
                    forget password
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="nav-link"
                    href={`${process.env.PUBLIC_URL}/thank-you`}
                    onClick={toggle}
                  >
                    thank you
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="nav-link"
                    href={`${process.env.PUBLIC_URL}/review`}
                    onClick={toggle}
                  >
                    review
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="nav-link"
                    href={`${process.env.PUBLIC_URL}/404`}
                    onClick={toggle}
                  >
                    404
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="nav-link"
                    href={`${process.env.PUBLIC_URL}/FAQ`}
                    onClick={toggle}
                  >
                    FAQ
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="nav-link"
                    href={`${process.env.PUBLIC_URL}/download`}
                    onClick={toggle}
                  >
                    download
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    className="nav-link"
                    href={`${process.env.PUBLIC_URL}/coming-soon`}
                    onClick={toggle}
                  >
                    coming soon
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            </Nav>
          </Collapse>
        </div>
      </Navbar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
          <StyledIconButton
            color="default"
            sx={{ mx: 1 }}
            onClick={handleClose}
            aria-label="next"
          >
            <ArrowForward />
          </StyledIconButton>
        </Box>
      </Modal>
    </>
  );
};

export default Menu;
