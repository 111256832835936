import React, { useEffect, useState } from "react";
import Menu from "modules/common/components/Navbar";
import { getPrivacy } from "store/actions/privacy_actions";
import { useDispatch, useSelector } from "react-redux";
import * as DOMPurify from "dompurify";
import { Box, CircularProgress } from "@mui/material";
import { Colors } from "helpers/colors";

const centerProgressStyle = {
  parent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  progress: {
    color: Colors.vendorForm,
  },
};

const Privacy = ({ privacy, handleNavigateHome }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.privacy.data);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getPrivacy(privacy, () => {
        setLoading(false);
      })
    );
  }, []);

  const cleanHTML = DOMPurify.sanitize(data);

  if (loading) {
    return (
      <Box sx={centerProgressStyle.parent}>
        <CircularProgress sx={centerProgressStyle.progress} />
      </Box>
    );
  } else
    return (
      <div>
        {/* Navbar Component*/}
        <Menu handleNavigateHome={handleNavigateHome} />

        {/*blog Section start*/}
        <div className="page-margin">
          {/*breadcrumb start*/}
          <div className="breadcrumb-bg">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-text-center d-align-center">
                  <h2 className="title">
                    <span>
                      {privacy == "terms"
                        ? "terms and conditions"
                        : "privacy policy"}
                    </span>
                  </h2>
                </div>
                <div className="col-md-6 col-sm-6 col-text-center">
                  <nav aria-label="breadcrumb" className="blog-bradcrumb ">
                    <ol className="breadcrumb bg-transparent mb-0">
                      <li className="breadcrumb-item">
                        <a href={`${process.env.PUBLIC_URL}/`}>Home</a>
                      </li>
                      <li className="breadcrumb-item active">
                        <a
                          href="javascript"
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {privacy == "terms"
                            ? "Terms and conditions"
                            : "Privacy policy"}
                        </a>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          {/*breadcrumb end*/}

          {/*sign in*/}
          <section>
            <div className="innerpage-decor">
              <div className="innerpage-circle1">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/Testimonial2.png`}
                  alt=""
                />
              </div>
              <div className="innerpage-circle2">
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/Testimonial1.png`}
                  alt=""
                />
              </div>
            </div>
            {/*faq in*/}
            <div className="faq">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <div
                        className="card border-theme mb-3 radius-0"
                        style={{ marginTop: -50 }}
                      >
                        <div>
                          <div
                            dangerouslySetInnerHTML={{ __html: cleanHTML }}
                            className="card-body"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*faq up*/}
          </section>
          {/*sign up*/}

          {/*Footer Section start*/}
          <div className="bg-light">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="copyright-section">
                    {/* <p>2018- 19 Copyright &copy; powered by Pixel Strap</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Footer Section End*/}
        </div>
        {/*blog Section start*/}
      </div>
    );
};

export default Privacy;
