import moment from "moment";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import AlertMessage from "modules/common/components/AlertMessage";
import { StyledIconButton } from "modules/common/components/StyledIconButton";
import { ArrowBack, ArrowForward, Close } from "@mui/icons-material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import CommentIcon from "@mui/icons-material/Comment";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useRef } from "react";
import { APIPATH } from "helpers/api";
import { Colors } from "helpers/colors";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PyneCommunity() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [imageLink, setImageLink] = useState("");
  // const [loaded, setLoaded] = useState(false);

  const CommunityPosts =
    useSelector((state) => state?.landing.CommunityPosts) ?? [];

  const sliderRef = useRef(null);

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const options = {
    arrows: false,
    accessibility: false,
    infinite: true,
    dots: false,
    dotClass: ".blog-carousel",
    margin: 30,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          infinite: true,
        },
      },
    ],
  };

  let DataList = CommunityPosts?.map((val, i) => {
    const postHasNoImage =
      !val?.image_one && !val?.image_two && !val?.image_three;
    return (
      <div className="blog-item" key={i}>
        <div
          style={{
            // border: `1px solid ${Colors.primaryVendor}`,
            padding: "15px",
            borderRadius: "12px",
          }}
        >
          <div className="blog-text">
            <h3 style={{ minHeight: "30px", maxHeight: "30px" }}>
              {val.description.length > 90
                ? val.description.substring(0, 7) + "..."
                : val.description}
            </h3>
            <h6 style={{ fontSize: "10px", textTransform: "capitalize" }}>
              {moment(val?.created_at).format("MMMM Do, YYYY h:mm A")}
            </h6>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              Posted by {val.client_name}
              <div className="like_comment_container">
                <ThumbUpIcon
                  sx={{ color: Colors.darkerGray }}
                  fontSize="small"
                />{" "}
                {val.likes}
                <CommentIcon
                  sx={{ color: Colors.darkerGray }}
                  fontSize="small"
                />{" "}
                {val.comments}
              </div>
            </div>
          </div>
          {val.image_one || val.image_two || val.image_three ? (
            <div
              style={{ backgroundColor: "transparent" }}
              className="blog-block"
              // style={{
              //   backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/team-img-bg.png)`,
              // }}
            >
              <div
                className="blog-box"
                style={{ backgroundColor: "transparent" }}
              >
                <div
                  className="overflow-hidden"
                  style={{ backgroundColor: "transparent" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                      height: "200px",
                    }}
                  >
                    {/* {val.image_one ? (
                      loaded === i ? ( */}
                    <img
                      onClick={() => {
                        setOpen(i);
                        setImageLink(APIPATH.BASELINKIMAGE + val.image_one);
                      }}
                      src={APIPATH.BASELINKIMAGE + val.image_one}
                      className="img-fluid community_image"
                      alt="pyne-community-post"
                      // onLoad={() => {
                      //   alert("hi");
                      //   setLoaded(true);
                      // }}
                    />
                    {/* ) : (
                        <CircularProgress
                          sx={{ fontSize: "small", mt: 3 }}
                          color="secondary"
                        />
                      )
                    ) : null} */}
                    {val.image_two ? (
                      <img
                        onClick={() => {
                          setOpen(i);
                          setImageLink(APIPATH.BASELINKIMAGE + val.image_two);
                        }}
                        src={APIPATH.BASELINKIMAGE + val.image_two}
                        className="img-fluid community_image"
                        alt="pyne-community-post"
                        // onLoad={() => setLoaded(i)}
                      />
                    ) : null}
                    {val.image_three ? (
                      <img
                        onClick={() => {
                          setOpen(i);
                          setImageLink(APIPATH.BASELINKIMAGE + val.image_three);
                        }}
                        src={APIPATH.BASELINKIMAGE + val.image_three}
                        className="img-fluid community_image"
                        alt="pyne-community-post"
                        // onLoad={() => setLoaded(i)}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {open === i ? (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={style}
              component={"img"}
              src={imageLink}
              className="img-fluid"
            />
          </Modal>
        ) : null}
      </div>
    );
  });

  return (
    <section id="pyne-community" className="blog">
      <div className="about-decor">
        <div className="about-circle1">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/team1.png`}
            className="img-fluid"
            alt="team1"
          />
        </div>
        <div className="about-circle2">
          <img
            src={`${process.env.PUBLIC_URL}/assets/images//main-banner1.png`}
            className="img-fluid"
            alt="main-banner1"
          />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="title">
              latest on <span>PYNE Community</span>
            </h2>
          </div>
          <div className="col-sm-12">
            {CommunityPosts?.length > 1 ? (
              <Slider
                ref={sliderRef}
                className="blog-carousel owl-carousel owl-theme rounded-dots slick-margin"
                {...options}
              >
                {DataList}
              </Slider>
            ) : (
              <AlertMessage
                message="Sorry. We couldn't find any community posts at this
               time..."
              />
            )}
            <Box
              sx={{
                mt: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <StyledIconButton
                color="default"
                sx={{ mx: 1 }}
                onClick={goToPrevSlide}
                aria-label="previous"
              >
                <ArrowBack />
              </StyledIconButton>

              <StyledIconButton
                color="default"
                sx={{ mx: 1 }}
                onClick={goToNextSlide}
                aria-label="next"
              >
                <ArrowForward />
              </StyledIconButton>
            </Box>
          </div>
        </div>
      </div>
    </section>
  );
}
